const ja = {
  translation: {
    common: {
      // TODO: Get the correct fallback error message.
      fallback_error_message: 'エラーが発生しました。',
    },
    // define translations below
    form: {
      required: '{{ field }}を入力してください。',
      email: '正しい{{ field }}を入力してください。',
      invalid_format: '正しい{{ field }}を入力してください。',
      password: {
        minLength: 'パスワードは8文字以上である必要があります。',
        confirm: '新しいパスワードと確認用パスワードが一致しません。',
        strong:
          'パスワードには、大文字1文字、特殊文字1文字、および少なくとも8文字が含まれている必要があります。',
      },
      not_registered: '入力したメールアドレスは登録されていません。',
      add_edit: {
        account_type: 'アカウント権限は必須項目です。',
        required: '{{ field }}は必須項目です。',
        invalid_date_format: '無効な日付のフォーマットです。',
        end_date_more_than_start_date:
          '契約期間の終了日は契約期間の開始日と等しいまたは以降でなくてはなりません。',
      },
      unsupported_file_type: 'ファイルの種類がサポートされていません。',
      max: {
        string: '{{ field }} は {{ max }} 文字を超えてはなりません。',
      },
      min: {
        array: '{{ field }} には、少なくとも {{ min }} 項目が必要です。',
      },
      min_options: '少なくとも{{min}}つ以上の選択肢を設定してください。',
      error_in_file_contents: 'ファイル内容に不備があります。',
      max_situations_exceeded: 'インポートエラーです。ファイルの内容を確認してください。',
      end_date_greater_than_start_date: '終了日は開始日より後でなければなりません。',
      invalid_date: '無効な日付です。',
      required_select: '検討結果が選択されていません（回答必須）',
      required_enter: '検討結果が入力されていません（入力必須）',
    },
    labels: {
      first_name: 'ファーストネーム',
      last_name: '苗字',
      remember_me: '私を覚えてますか',
      forgot_password: 'パスワードを忘れた方はこちら',
      email_address: 'メールアドレス',
      password: 'パスワード',
      confirm_password: 'パスワードを認証する',
      reset_password: 'パスワードを再設定する',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワード（確認用）',
      enter_keyword: 'キーワードを入力してください',
      get_started: 'はじめましょう',
      integrations: '統合',
      fullname: 'フルネーム',
      inquiry_content: 'お問合わせ内容',
      navigation: 'ナビゲーション',
      resources: 'リソース',
      action: 'アクション',
      showPassword: 'パスワードを表示',
      hidePassword: 'パスワードを隠す',
      role: '役割',
      notifications: '通知',
      noNotifications: '新しい通知はありません。',
      newNotification: '新しい通知があります。',
      auth_code: '認証コード',
      no_data: '表示するデータがありません。',
      narrow_down: '絞り込み：',
      search: '検索',
      required_item: '必須項目',
      half_width_numbers_only: '半角数字のみ入力',
      half_width_numbers_only_no_hypens: '(半角のみ入力、ハイフン無し)',
      cannot_change_account_type: '自アカウントの権限を変更することはできません。',
      half_width_digit: '半角数字',
    },
    placeholder: {
      email_address: 'メールアドレスを入力してください',
      password: 'パスワードを入力してください',
      address: '市区町村、番地、ビル名等',
      contractor_email_add: '契約者メールアドレス',
      contractor_contact_email_add: '連絡用メールアドレス（緊急連絡用）',
      select_file: 'ファイルを選択',
      content_selection: '検索またはコンテンツ選択',
    },
    buttons: {
      add_new: '新しく追加する',
      back_to_list: '一覧に戻る',
      back_to_list_screen: '一覧画面へ戻る',
      back_to_login: 'ログインページに戻る',
      cancel: 'キャンセル',
      close: '閉じる',
      conducted: '訓練実施',
      confirm: '確認',
      create: '新規登録',
      csv_export: 'CSVエクスポート',
      delete: '削除',
      documentation: 'ドキュメンテーション',
      duplicate: '複製して新規作成',
      edit: '編集',
      image_upload: '画像アップロード',
      login: 'ログイン',
      no: 'いいえ',
      reconducted: '再実施',
      reset: 'リセット',
      return: '戻る',
      return_to_login: 'ログイン画面に戻る',
      save: '保存する',
      settings: '設定',
      signup: 'サインアップ',
      store: '保存',
      submit: '送信',
      update: 'アップデート',
      upload: 'アップロード',
      yes: 'はい',
      keep: '保存',
      reference: '参照',
      no_selection: '選択せずに進む',
      selection_complete: '選択完了',
      delivery_cancel: '配信キャンセル',
      various_edit: '各種編集',
      training_data_export: '訓練データエクスポート',
      distribution: '配信管理一覧へ',
      next: '次へ',
      end: '終了',
      return_to_create_training: 'シナリオ作成画面に戻る',
      session_end: '訓練終了',
      return_to_list: '一覧へ戻る',
      training_cancel: 'キャンセル ',
      training_submit: '次へ進む ',
      continue_session: '続きから実施',
      reset_session: '最初からやり直し',
      leave: 'このページを離れる ',
      force_login: '強制ログイン',
    },
    toast: {
      user_reset_password_success: 'このユーザーのパスワードリセットに成功しました。',
      update_success: '{{moduleName}}の変更内容を保存しました。',
      create_success: '新規{{moduleName}}を登録しました。',
    },
    pages: {
      error: {
        title: 'リクエスト失敗',
        sub_heading: '予期しないエラーが発生しました。',
      },
      login: {
        title: 'BCP定着・レジリエンス <br>強化支援サービス',
        below_logo: '信用金庫向け演習 edition',
      },
      logout: {
        title: 'ログアウトしました。',
        sub_heading: 'ログインページに戻るかシステムを閉じてください。',
      },
      otp: {
        sub_heading: 'へ送信したメール本文の認証コードを入力してください。',
        resend: '再送信',
        remaining: '残り：',
        tooltip:
          '再送信は5回まで。制限回数を超えたらログイン画面へ戻り、再度ログインしてください。',
        required: '認証コードは必須項目です。',
      },
      signup: {
        agree_to_terms: '[サインアップ]をクリックすると、読んだことに同意したことになります',
        signup_complete:
          '確認メールが受信トレイに送信されました。 リンクをクリックして、登録プロセスを完了します。',
        terms_conditions: '規約と条件',
        create_free_account: '無料アカウントを作成する',
      },
      forgot_password: {
        sub_heading: 'ご登録のメールアドレスに、 <br>パスワード再設定のためのURLを送信します。',
        success: 'パスワードをリセットする方法については、受信トレイを確認してください。',
        email_sent: 'メールを送信しました。',
        instructions: 'メールに記載された手順に従ってパスワードを再設定してください。',
      },
      reset_password: {
        sub_heading: '新しいパスワードを入力してください。',
        success: 'パスワードは正常に更新されました。',
        submit: 'パスワード変更',
        login_new_password: '新しいパスワードでログインしてください。',
        completed: 'パスワード再設定完了',
        max_characters: '12文字以上である',
        pattern: '英大文字、英小文字、半角数字、記号のうち 3種類以上の組み合わせで設定',
        symbols: '※ 利用できる記号 . _ - = # ! ?',
        email_sent: 'メールを送信しました。',
        instructions: 'メールに記載された手順に従ってパスワードを再設定してください。',
      },
      users: {
        user_created: 'ユーザーが作成されました。',
        user_updated: 'ユーザーの詳細が更新されました。',
        user_deleted: 'ユーザーが削除されました',
        add_user: 'ユーザーを追加する',
        edit_user: 'ユーザー編集',
        delete_user: 'ユーザーを削除',
        first_name: 'ファーストネーム',
        last_name: '苗字',
        email_address: '電子メールアドレス',
        status: '状態',
        role: '役割',
        delete_confirmation: '選択したユーザーを削除してもよろしいですか？',
      },
      activate: {
        heading: 'アカウントをアクティブ化',
        subtitle: 'アカウントをアクティブ化するためのパスワードを設定します。',
        activated: 'アカウントが有効になりました。 これで、アカウントにログインできます。',
      },
      dashboard: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        new_users: '新しいユーザー',
        total_sales: '総売上高',
        total_orders: '総注文数',
      },
      not_found: {
        title: 'ページが見つかりません',
        sub_heading: 'お探しのページは、移動または削除された可能性があります。',
        note: '※パスワード再設定用URLの有効期限が過ぎた場合は、はじめから再操作してください。',
        back_to_top: 'トップページへ戻る',
      },
      faq: {
        heading: 'よくあるご質問',
        sub_heading: 'お客様からお問い合わせいただく質問をQ&A形式でまとめました。',
      },
      inquiry: {
        heading: 'お問い合わせ',
        sub_heading: 'このままお問い合わせされる方は下記のフォームにご入力ください。',
        success_message: 'お問い合わせを送信しました。',
        failed_message: '送信中にエラーが発生しました。',
      },
      profile: {
        heading: 'プロファイル編集',
        sub_heading: 'アカウント情報を更新します。',
        success_message: '詳細が正常に更新されました。',
        failed_message: '更新に失敗しました。',
      },
      landing: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        why_heading: 'なぜベーステンプレートを使用するのですか？',
        docker: {
          heading: '柔軟な環境',
          description:
            '「自分のマシンで動作する」という問題を完全に解消します。 環境のセットアップ、環境固有の問題のデバッグ、およびより移植性が高くセットアップが簡単なコードベースに費やす時間を短縮します。',
        },
        react: {
          heading: '高速で直感的なUI',
          description:
            'ReactJSは非常に直感的に操作でき、UIのレイアウトに双方向性を提供します。 これらのコンポーネントを利用して1つの場所に統合できるように、構成可能です。 したがって、コードははるかに保守可能で柔軟になります。',
        },
        laravel: {
          heading: '強力なAPI',
          description:
            'LaravelのAPI機能を利用してバックエンドAPIを簡単に開発できます。 サードパーティの統合とライブラリが簡単で、すばやく簡単です。',
        },
        our_customers_heading: 'お客様',
        reviews_heading: '私たちのクライアントが言うこと',
        see_all_reviews: 'すべてのレビューを見る',
        call_to_action: '今すぐアプリケーション開発を加速しましょう!',
      },
      about: {
        main_heading: '私たちの物語',
        sub_heading:
          '私たちは、私たちが信じる人々のために誇りに思う作品をデザイン、作成、制作するために協力しています。',
        meet_the_team: 'チームに会う',
        team_description:
          '思いやり、独創性、細部へのこだわりは、私たちが設計、製造、販売するすべての製品の基盤です。',
        our_mission: '私たちの使命',
        mission_description:
          '私たちの使命は、ビジネスのトレンドと人々中心の文化と行動を重視する提案を重視する高品質のサービスと製品で、卓越したテクノロジーを広めることです。',
        our_activities: '私たちの活動',
        activities_description: '生計を立てるのに忙しくて、生計を立てることを忘れないでください。',
      },
      roles: {
        role_created: 'ロールが作成されました。',
        role_updated: '役割の詳細が更新されました。',
        role_deleted: '役割が削除されました。',
        add_role: '役割の追加',
        edit_role: '役割の編集',
        delete_role: '役割の削除',
        name: '名前',
        permissions: '許可',
        delete_confirmation: '選択した役割を削除してもよろしいですか?',
      },
      unauthorized: {
        main_heading: '許可されていません。',
        sub_heading: '申し訳ありませんが、このリソースにアクセスする権限がありません。',
      },
      account: {
        title: 'アカウント管理',
        list: '- 一覧 -',
        no_of_employees: '従業員数',
        plan_limit: '利用ユーザー数 / プランの上限数',
        search: 'アカウント検索',
        company_name: '企業名',
        result: '表示件数',
        employee_code: '従業員番号',
        name: '氏名',
        department: '部門',
        location: '勤務地',
        email: 'メールアドレス',
        job_title: '役職',
        leader: '部門リーダー',
        role: '権限',
        status: 'ステータス',
        system_admin: 'システム管理者',
        consultant: 'コンサル',
        company_admin: '管理者',
        user: '一般',
        user_code: 'ユーザーコード',
        furigana_name: 'フリガナ',
        birth_date: '生年月日',
        password: 'パスワード',
        create_edit_view_password: 'パスワード更新日',
        department_leader_flag: '部門リーダーフラグ',
        account_type: 'アカウント権限',
        information_list: 'アカウント管理一覧',
        on: 'ON',
        off: 'OFF',
        valid: '有効',
        invalid: '無効',
        last_name: '姓',
        first_name: '名',
        last_name_kana: 'セイ',
        first_name_kana: 'メイ',
        enter_last_name: '姓を入力',
        enter_first_name: '名を入力',
        enter_last_name_kana: 'セイを入力',
        enter_first_name_kana: 'メイを入力',
        limit_reached:
          '利用プランのユーザー上限数を超える登録はできません。利用プラン変更をご希望の際はシステム管理者へお問い合わせください。',
        register_subtitle: '- 新規登録 -',
        register_breadcrumb: 'アカウント新規登録',
        edit_subtitle: '- 編集 -',
        edit_breadcrumb: 'アカウント編集',
        view_subtitle: '- 詳細 -',
        view_breadcrumb: 'アカウント詳細',
        employee_number: '従業員No',
      },
      my_page: {
        title: 'マイページ',
      },
      company: {
        title: '企業管理',
        list: '- 企業管理一覧',
        name: '企業名',
        period: '契約期間',
        usage_plan: '利用プラン',
        usage_limit: '利用ユーザー数上限',
        functions: '機能',
        no_of_items: '表示件数',
        no_of_registrations: '登録数',
        company_code: '企業コード',
        postal_code: '郵便番号',
        postal_code_data: '〒',
        address: '住所',
        phone_number: '電話番号',
        industry: '業種・業態',
        service_usage: 'サービス利用',
        contractor: '契約者',
        contractor_name: '契約者名',
        contractor_email_add: '契約者 メールアドレス',
        contractor_contact_email_add: '連絡先 メールアドレス',
        option: 'オプション',
        enabled: '利用可能',
        disabled: '利用停止',
        prefecture: '都道府県',
        document_management: '文書管理',
        edit_info: '企業情報編集',
        details: '詳細',
        department_list: '部門一覧',
        has_changes: '編集中のデータがあります。タブを切り替えたい場合は、保存してください。',
        modal: {
          discard_changes: {
            title: 'ページ移動の確認',
            message: 'ページを移動すると作成中の情報は保存されま\nせん。移動しますか？',
          },
        },
      },
      department: {
        name: '部門名',
        add_child: '子カテゴリを追加',
        create_department: '部門登録',
        edit_department: '部門編集',
        in_use: '選択した部門はユーザーアカウントで設定されており削除出来ません。',
      },
      location: {
        title: '勤務地情報',
        name: '勤務地名',
        postal_code_1: '郵便',
        postal_code_2: '番号',
        prefecture: '都道府県',
        address: '市区町村、番地',
        building: 'ビル名等',
        note: 'のドラッグ&ドロップで順序・階層を変更できます。',
        close_all: 'すべて閉じる',
        in_use: '選択した勤務地はユーザーアカウントで設定されており削除出来ません。',
      },
      training: {
        title: '訓練シナリオ管理',
        list: '一覧',
        title_column: '訓練タイトル',
        event_category: '事象',
        target_phase: 'フェーズ',
        target_area: 'エリア',
        author: '更新者',
        updated_at: '更新日時',
        training_type: '訓練種別',
        functions: '機能',
        collective_training: '集合訓練',
        individual_training: '個人訓練',
        file_selection: 'ファイル選択',
        upload: {
          file_selection: 'ファイル選択',
          instruction: 'このエリアにファイルをドラッグ&ドロップしてアップロードしてください。',
          supported_formats: 'サポートされているファイル形式：',
        },
        excel: {
          step: '※ STEP5まで入力するとシナリオが生成されます。',
          scenario: 'シナリオ(エクセル)ファイルインポート',
        },
        create_title: '訓練シナリオ作成',
        edit_title: '訓練シナリオ編集',
        list_breadcrumb: '訓練シナリオ一覧',
        basic_info: '■ 基本情報入力',
        select_scenario: '■ シナリオパターンの選択',
        target_area_location: '対象エリア(勤務地)',
        basic_information: '基本情報',
        reset: '初期化',
        all: 'すべて',
        target_phase_select: '対象フェーズ',
        step1: 'STEP1 - 事象',
        step2: 'STEP2 - 事例サンプル',
        step3: 'STEP3 - 対象期間',
        step4: 'STEP4 - 被害規模',
        step5: 'STEP5 - 対象文書',
        step6: 'STEP6 - 訓練タイトル・概要',
        step7: 'STEP7 - 対象ユーザー',
        step8: 'STEP8 - 訓練期間',
        step9: 'STEP9 - 訓練対象者への案内',
        disaster_day: '発災当日',
        after_disaster_day: '発災翌日',
        third_disaster_day: '発災3日後',
        one_week_disaster_day: '発災1週間後',
        two_week_disaster_day: '発災2週間後',
        excel_import: 'Excelインポート',
        or: 'または',
        character_limit: '入力可能文字数：{{ value }}文字まで',
        training_overview: '訓練概要',
        select_target_users: '対象ユーザー選択',
        target_users_count: '選択した対象ユーザー数：',
        start_date: '訓練開始日時',
        end_date: '訓練終了日時',
        end_date_delivery: '訓練終了日',
        participants_info: '訓練対象者への案内',
        erp_tooltip: '緊急事態発生時の最初の対応(連絡・避難・情報収集など)',
        cmp_tooltip: '危機に直面した際の対応体制の整備(対策本部での情報収集と発信・実行指示など)',
        bcp_tooltip: '重要事業の中断防止・損害最小化・復旧に関する対応(代替方法・復旧計画など)',
        others_tooltip: 'シナリオインポート時は「その他」を選択',
        tokyo_tooltip: '関東地方（東京、神奈川、埼玉、千葉）/被害拡',
        nankai_tooltip: '西日本・関東広域/大きな被害',
        exam_number: '検討事項{{number}}',
        explanation: '解説',
        response_method: '回答方法',
        image_upload: {
          hover: 'アップロード画像はプレビュー画面で確認できます。',
          limit_size_exceeded: '画像およびファイルのサイズが上限を超えています。',
          file_type_not_supported: 'このファイル形式は対象外です。',
          uploads_exceeded: 'アップロード上限数に達しています。',
        },
        free_text: '自由記述',
        single: '単一選択',
        multiple: '複数選択',
        optional_exercise: '選択式例題{{number}}',
        select_all_apply: '※ 当てはまるすべてを選択',
        erp: 'ERP(初期対応)',
        cmp: 'CMP(危機管理)',
        bcp: 'BCP(事業継続)',
        others: 'その他',
        earthquake: '地震',
        cyber_attack: 'サイバー攻撃',
        tokyo_option: '首都直下地震',
        nankai_option: '南海トラフ地震',
        big: '大',
        scenario_preview: 'シナリオプレビュー',
        scenario_id: '■ シナリオNo.',
        scenario_count: '状況数：',
        description: '訓練の説明',
        time_limit: '検討時間(目安)：',
        company_to_be_delivered_column: '配信予定企業',
        status: 'ステータス',
        change_training_period: '訓練期間日時変更',
        change_training_users: '対象ユーザー変更',
        number_of_registrations: '登録数（{{ number }}件）',
        num_of_others: '他{{ number }}社',
        delivery_title: '訓練配信管理',
        preview: 'プレビューで確認',
        save_distribute: '保存&配信',
        scenario_generation: 'シナリオ生成',
        update_questions: '設問を更新',
        update_explanations: '解説を更新',
        review_time: '検討時間：',
        time_display: '時間表示のみ',
        uploaded_images: 'アップロード画像数：',
        time_remaining: '残り{{value}}秒',
        num_of_employees: 'ユーザー数',
        num_of_selected_users: '選択数 : {{ number }}件',
        target_period_update_success: '訓練期間を変更しました。',
        target_user_update_success: '対象ユーザーを変更しました。',
        consideration_remaining_time: '検討時間(目安)残り：',
        consideration: '検討事項',
        scenario_info: 'シナリオ情報',
      },
      collective_training: {
        title: '集合訓練',
        list: '一覧',
        title_column: '訓練タイトル',
        event_category: '事象',
        start_date: '訓練開始日時',
        end_date: '訓練終了日時',
        period_status: 'ステータス',
        implementation_status: '実施状況',
      },
    },
    menu: {
      home: 'ホーム',
      about: '約',
      inquiry: 'お問い合わせ',
      faq: 'よくあるご質問',
      dashboard: 'ダッシュボード',
      users: 'アカウント管理',
      orders: '注文',
      reports: 'レポート',
      integrations: '統合',
      profile: 'プロフィール',
      logout: 'ログアウト',
      terms: '利用規約',
      privacy_policy: 'プライバシーポリシー',
      documentation: 'ドキュメンテーション',
      api_reference: 'APIリファレンス',
      support: 'サポート',
      styleguide: 'スタイルガイド',
      roles: '役割',
      broadcast: '放送',
      company: '企業管理',
      document: '文書管理',
      training: '訓練シナリオ管理',
      my_page: 'マイページ',
      individual_training: '個人訓練実施',
      group_training: '集合訓練実施',
      training_delivery: '訓練配信管理',
    },
    module: {
      company: '企業',
      user: 'ユーザー',
    },
    table: {
      no_data: 'データなし。',
    },
    footer: {
      copyright: 'Copyright © Since 2024 Newton Consulting Ltd. All rights reserved.',
    },
    modal: {
      logout_message: 'ログアウトしてもよろしいでしょうか？',
      confirm_force_login: {
        title: '重複ログインの確認',
        message: `すでに同じアカウントでログインしています。
        この画面でログインすると、現在ログイン済の別の端末・画面は強制的にログアウトされます。

        ※前回のログイン時に「ログアウト」操作をしていない際にも、このメッセージが表示される場合があります。

        この画面で強制ログインしますか？`,
      },
      delete_user: {
        title: 'ユーザーの削除',
        message: '削除してもよろしいでしょうか？',
        confirm: '削除が完了しました。',
      },
      training_validation_error: {
        title: '保存できませんでした。',
        message: '入力項目をご確認してください。',
      },
      delete_company: {
        title: '企業の削除',
        message: '選択した企業を削除しますか？\n企業に登録されているユーザーも削除されます。',
        confirm: '削除が完了しました。',
      },
      reset_password: {
        title: 'パスワードのリセット',
        message: '選択したユーザーのパスワードを リセットしてもよろしいですか?',
        confirm: {
          title: 'パスワードリセットの完了',
          message:
            'パスワードのリセットが完了しました。 パスワード再設定を依頼するメールが ユーザーへ送信されます。',
        },
      },
      disregard_changes: {
        title: 'ページ移動の確認',
        message: '変更内容は廃棄されます。\n変更を保存しなくてもよろしいですか？',
      },
      my_page: {
        reset_password: {
          title: 'パスワードのリセット',
          message:
            'パスワードをリセットしますか？\nパスワード再設定のためのメールを、ご登録の\nメールアドレスへ送信します。\n\n   <span style="color: #1D85E8;">※メール送信の際に、本画面からログアウトします</span>',
          success: {
            title: 'パスワードリセットの完了',
            confirm:
              'パスワード再設定のためのメールを送信しました。\nメールに記載された手順に従ってパスワードを再設定してください。\n\n   <span style="color: #1D85E8;">※再設定の際にログアウトします。</span>',
          },
        },
      },
      image_upload: {
        title: '画像ファイルアップロード',
      },
      training_confirm_preview: {
        title: 'シナリオ保存の確認',
        message: 'ここまでの内容を保存しますか？',
      },
      training_save_distribute: {
        title: '保存&配信',
        message: 'シナリオを保存し、対象者に公開しました。',
      },
      training_save: {
        title: '保存',
        message: 'シナリオを保存しました。',
      },
      delete_image: {
        title: '画像ファイルの削除',
        message: '選択した画像ファイルを削除しますか？',
      },
      collective_training: {
        title: '訓練開始',
        message: '以下の訓練を開始します。',
        note: '※ ブラウザの「戻る」ボタンは使用しないでください。',
        training_title: '訓練タイトル',
        training_outline: '訓練概要',
        information: 'ご案内',
      },
      training_delivery_cancel: {
        message: '選択した 訓練計画 の配信をキャンセルしてもよろしいですか？',
      },
      training_period: {
        title: '訓練期間日時変更',
      },
      review_time_over: {
        title: '検討時間(目安)終了',
        message:
          '目安の検討時間を過ぎました。\n' + ' 検討結果の入力を終え、次の画面へ進んでください。',
      },
      training_completed: {
        title: '訓練終了',
        message: '以上で訓練は終了です。\n' + ' お疲れさまでした。',
      },
      target_users: {
        show_all: 'すべて表示',
        show_selected: '選択済で絞り込み',
        show_unselected: '未選択で絞り込み',
      },
      training_move_to_another_page: {
        title: '次状況への進行確認',
        message:
          '検討結果の入力を確定し、次の状況へ進みます。 \n' +
          '<span style="color: #1D85E8;">※ 次へ進んだ後は、現在のページに戻って入力内容を変更することはできません。</span>',
      },
      end_training_confirmation: {
        title: '訓練終了の確認',
        message:
          '検討結果の入力を確定し、訓練を終了します。 \n' +
          '<span style="color: #1D85E8;">※ 訓練終了後は、入力内容を変更することはできません。</span>',
      },
      consideration_error: {
        title: '検討結果の入力の確認',
        message:
          '以下の検討結果を入力・選択してください。\n' +
          '<span style="color: #1D85E8;">(検討事項 {{ number }})</span>',
      },
      term_policy: {
        title: '利用規約とプライバシーポリシー',
        confirm: '※ 下記内容のご確認・ご同意の上、本サービスをご利用ください。',
        terms: '利用規約',
        agree_terms: '利用規約に同意する',
        policy: 'プライバシーポリシー',
        agree_policy: 'プライバシーポリシーに同意する',
      },
      continue_session: {
        title: '実施中訓練の確認',
        message: '訓練シナリオの途中までの入力内容が保存されています。続きから実施しますか？',
      },
      training_update_conflict: {
        title: 'シナリオ同時編集エラー',
        message:
          '編集中に他のユーザーがデータを更新したため、保存できません\n一覧画面へ戻り、再度編集してください。',
        back_to_list: '一覧画面へ戻る',
      },
      back_navigation: {
        title: 'このページを離れますか？',
        message: '訓練を中断し、一覧ページへ移動します。',
        note: '※ 本ページの入力内容はまだ保存していません。',
      },
      training_in_progress: {
        title: '訓練実施期間中の確認',
        message: 'このシナリオで実施期間中の訓練があります。\n編集画面へ進みますか？',
      },
      training_concurrent_edit: {
        title: '同時編集の確認',
        message: 'このシナリオを編集中のユーザーがいます。\n編集画面へ進みますか？',
      },
    },
    role_type: {
      System_Admin: 'システム管理者',
      Consultant: 'コンサル',
      Company_Admin: '管理者',
      User: '一般',
      translate: {
        システム管理者: 'System Admin',
        コンサル: 'Consultant',
        管理者: 'Company Admin',
        一般: 'User',
      },
    },
  },
};

export default ja;
