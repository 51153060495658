import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const AnimatedLinearProgress = styled(LinearProgress)({
  [`& .${linearProgressClasses.bar}`]: {
    animation: 'linearEffect 2s infinite ease-in-out',
    '@keyframes linearEffect': {
      from: {
        transform: 'translateX(-95%)',
      },
      to: {
        transform: 'translateX(0%)',
      },
    },
  },
});

function Loader() {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <AnimatedLinearProgress variant="determinate" value={0} />
      </Box>
    </>
  );
}

export default Loader;
