import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.user = action.payload;
    },
    updateUserCount: (state, action) => {
      if (state.user) {
        state.user.users_count = action.payload;
      }
    },
  },
  selectors: {
    selectProfileUser: (state) => state.user,
  },
});

export const { setProfile, updateUserCount, addBookmark, removeBookmark } = profileSlice.actions;

export const { selectProfileUser } = profileSlice.selectors;

export default profileSlice.reducer;
