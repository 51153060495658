const admin = [
  {
    path: '/admin',
    redirectTo: '/admin/company',
  },
  {
    path: '/admin/users',
    component: 'pages/authenticated/Users',
    auth: true,
  },
  {
    path: '/admin/users/create',
    component: 'pages/authenticated/admin/user/UserDetails',
    auth: true,
  },
  {
    path: '/admin/users/details/:id',
    component: 'pages/authenticated/admin/user/UserView',
    auth: true,
  },
  {
    path: '/admin/users/update/:id',
    component: 'pages/authenticated/admin/user/UserDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/admin/roles',
    component: 'pages/authenticated/admin/Roles',
    auth: true,
  },
  {
    path: '/admin/integrations',
    component: 'pages/authenticated/admin/Integrations',
    auth: true,
  },
  {
    path: '/admin/company',
    component: 'pages/authenticated/admin/company/Companies',
    auth: true,
  },
  {
    path: '/admin/company/create',
    component: 'pages/authenticated/admin/company/Company',
    auth: true,
  },
  {
    path: '/admin/company/details/:id',
    component: 'pages/authenticated/admin/company/CompanyView',
    auth: true,
  },
  {
    path: '/admin/company/update/:id',
    component: 'pages/authenticated/admin/company/Company',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/admin/training',
    component: 'pages/authenticated/admin/Training',
    auth: true,
  },
  {
    path: '/admin/training_delivery',
    component: 'pages/authenticated/admin/training/TrainingDelivery',
    auth: true,
  },
  {
    path: '/admin/training/create',
    component: 'pages/authenticated/admin/training/TrainingAddEdit',
    auth: true,
  },
  {
    path: '/admin/training/update/:id',
    component: 'pages/authenticated/admin/training/TrainingAddEdit',
    auth: true,
  },
  {
    path: '/admin/training/preview/:id',
    component: 'pages/authenticated/admin/training/TrainingPreview',
    auth: true,
  },
  {
    path: '/admin/group_training',
    component: 'pages/authenticated/admin/GroupTraining',
    auth: true,
  },
  {
    path: '/admin/group_training/:id',
    component: 'pages/authenticated/TrainingSession',
    auth: true,
  },
  // DEMO PURPOSES ONLY. REMOVE ON ACTUAL PROJECT
  {
    path: '/admin/broadcast',
    component: 'pages/authenticated/admin/Broadcast',
    auth: true,
  },
];

export default admin;
