// metrics in "rem" not "px"
const pxToRem = (val) => `${val / 20}rem`;

/**
 * MUI Typography
 * @see https://mui.com/material-ui/customization/typography/
 */
const typography = {
  fontFamily: '"Noto Sans JP"',
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 700,
  },
  h2: {
    fontSize: pxToRem(42),
    fontWeight: 700,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: 700,
  },
  h4: {
    fontSize: pxToRem(30),
    fontWeight: 700,
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 700,
  },
  h6: {
    fontSize: pxToRem(18),
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: 300,
  },

  // regular
  regular12: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  regular13: {
    fontSize: pxToRem(13),
    fontWeight: 400,
  },
  regular14: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  regular15: {
    fontSize: pxToRem(15),
    fontWeight: 400,
  },
  regular16: {
    fontSize: pxToRem(16),
    fontWeight: 400,
  },
  regular17: {
    fontSize: pxToRem(17),
    fontWeight: 500,
  },

  // medium
  medium14: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  medium15: {
    fontSize: pxToRem(15),
    fontWeight: 500,
  },
  medium16: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  medium18: {
    fontSize: pxToRem(18),
    fontWeight: 500,
  },
  medium22: {
    fontSize: pxToRem(22),
    fontWeight: 500,
  },
  medium24: {
    fontSize: pxToRem(24),
    fontWeight: 500,
  },
  medium28: {
    fontSize: pxToRem(28),
    fontWeight: 500,
  },

  // bold
  bold15: {
    fontSize: pxToRem(15),
    fontWeight: 700,
  },
  bold18: {
    fontSize: pxToRem(18),
    fontWeight: 700,
  },
};

export default typography;
