const companyAdmin = [
  {
    path: '/company-admin',
    redirectTo: '/company-admin/users',
  },
  {
    path: '/company-admin/users',
    component: 'pages/authenticated/Users',
    auth: true,
  },
  {
    path: '/company-admin/users/create',
    component: 'pages/authenticated/admin/user/UserDetails',
    auth: true,
  },
  {
    path: '/company-admin/users/details/:id',
    component: 'pages/authenticated/admin/user/UserView',
    auth: true,
  },
  {
    path: '/company-admin/users/update/:id',
    component: 'pages/authenticated/admin/user/UserDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/company-admin/training_delivery',
    component: 'pages/authenticated/admin/training/TrainingDelivery',
    auth: true,
  },
  {
    path: '/company-admin/training',
    component: 'pages/authenticated/admin/Training',
    auth: true,
  },
  {
    path: '/company-admin/training/create',
    component: 'pages/authenticated/admin/training/TrainingAddEdit',
    auth: true,
  },
  {
    path: '/company-admin/training/update/:id',
    component: 'pages/authenticated/admin/training/TrainingAddEdit',
    auth: true,
  },
  {
    path: '/company-admin/training/preview/:id',
    component: 'pages/authenticated/admin/training/TrainingPreview',
    auth: true,
  },
  {
    path: '/company-admin/group_training',
    component: 'pages/authenticated/admin/GroupTraining',
    auth: true,
  },
  {
    path: '/company-admin/group_training/:id',
    component: 'pages/authenticated/TrainingSession',
    auth: true,
  },
];

export default companyAdmin;
