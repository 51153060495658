/**
 * @type {import('@mui/material').ThemeOptions['components']['MuiButton']}
 */
export const MuiButton = {
  variants: [
    {
      props: { variant: 'outlined' },
      style: (props) => {
        const color = props.theme.palette[props.color]?.main;
        return {
          ...props.theme.typography.bold15,
          border: `1px solid ${color}`,
        };
      },
    },
  ],
};
