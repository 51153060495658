/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette = {
  primary: {
    main: '#003B6C',
    contrastText: '#fff',
  },
  secondary: {
    main: '#D14081',
    contrastText: '#fff',
  },
  success: {
    main: '#019612',
    contrastText: '#fff',
    primary: '#019612',
  },
  error: {
    main: '#ED0808',
    contrastText: '#fff',
  },
  warning: {
    main: '#F3C969',
    contrastText: '#fff',
  },
  info: {
    main: '#9DD1F1',
    contrastText: '#fff',
  },
  transparent: {
    main: '#fff',
    dark: '#888',
  },
  text: {
    main: '#5E5F62',
    secondary: '#3C3C3C',
  },
  modal: '#191919',
  link: '#1D85E8',
  footer: '#3F3F3F',
  divider: 'rgba(0,0,0,0.05)',
  border: '#DBDBDB',
  even: '#FCFCFD',
  odd: '#F5F5F5',
  disabled: {
    main: '#9D9D9D',
    background: '#FCFCFD',
    text: '#CBCBCB',
    border: '#E4E4E4',
    field: '#EEEEEE',
  },
  pagination: {
    background: '#E3E3E3',
    selected: '#003B6D',
    hover: '#00294b',
  },
  scrollbar: {
    track: '#f1f1f1',
    thumb: '#bbbbbb',
    hover: '#8a8888',
  },
  switch: '#5BC6F9',
  sidebar: {
    main: '#FAFAFA',
    hover: '#265988',
    active: '#FCE00D',
    leftBarAdmin: '#FD5919',
    leftBarCompanyAdmin: '#6EB8FF',
    leftBarCompanyUser: '#03D017',
  },
  delete: '#de3e15',
  container: '#FCFCFD',
  orange: {
    main: '#FD5919',
  },
  lightgreen: {
    main: '#B0C415',
  },
  ribbon: '#38C8DB',
  lightOrange: '#FFF9F0',
  purple: '#BC7CDF',
  darkGray: '#E8E8E8',
  button: {
    border: '#C3C3C3',
  },
};

export default palette;
