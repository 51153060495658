const companyUser = [
  {
    path: '/company-user',
    redirectTo: '/company-user/group_training',
  },
  {
    path: '/company-user/group_training',
    component: 'pages/authenticated/admin/GroupTraining',
    auth: true,
  },
  {
    path: '/company-user/group_training/:id',
    component: 'pages/authenticated/TrainingSession',
    auth: true,
  },
];

export default companyUser;
