import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from 'components/atoms/Loader';
import routes from './routes';

const AdminLayout = lazy(() => import('templates/Authenticated'));
const UserLayout = lazy(() => import('templates/User'));
const Logout = lazy(() => import('pages/guest/Logout'));
const Pages = routes.map((route) => lazy(() => import(`../${route.component}`)));

function Router() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map((route, i) => {
          const Page = Pages[i];
          const layout = route.auth ? <AdminLayout /> : <UserLayout navbar={route.navbar} />;

          if (route.redirectTo) {
            return (
              <Route key={i} element={layout}>
                <Route exact path={route.path} element={<Navigate to={route.redirectTo} />} />
              </Route>
            );
          }

          return (
            <Route key={i} element={layout}>
              <Route exact path={route.path} element={<Page />} />
            </Route>
          );
        })}

        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </Suspense>
  );
}

export default Router;
